.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.connectButton
{
    bottom: 0px;
    position: fixed;
    z-index: 20;
}

.connectButtonHeader
{
  margin: auto;

  margin-right: 10px;

}

.top-left {
  position: absolute;
  top: 50px; /* Adjust as needed */
  left: 50px; /* Adjust as needed */
  width: 75px; /* Set width */
  height: 75px; /* Set height */
}

.titleApp
{
  color : white;
  font-family : "Open Sans";
  margin: auto;
  text-align: center;
  margin-top: 10%;

}

.titleApp h1
{
  margin-top: 0 !important;
  text-transform: uppercase;
}

body
{
  background-color: #F4F7FE;
  /*background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);*/
  height: 100vh;
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  letter-spacing: 0.2px;

}

@media only screen and (max-width: 799px) {
  .contentComponent
  {
    margin-left: 0px !important;
    padding-top: 10px !important;
  }

  span
  {
    font-size: 11px !important;
  }
  button
  {
    font-size: 11px !important;
  }

  td
  {
    font-size: 11px;
  }

  h3
  {
    font-size: 13px !important;
  }
}

.contentComponent
{
  display: table-row;
  width: auto;
  min-width: 0;
  flex-direction : column;
  align-items : flex-end;
  margin-left: 24px;
  margin-bottom : 20px;
  margin-right: 24px;
  margin-top: 25px;
}

.bodyComponent
{
  display: table;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html
{
  min-height: 100% !important;
}


#root
{
  height: 100%;
}


.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.drawerConnect
{
  margin-left: 20px;
  margin-bottom: 50px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.center

{
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}


body {
  font-family: 'Poppins', sans-serif; /* Use Poppins font */
}

h1, h2, h3 {
  font-family: 'DM Sans', sans-serif; /* Use DM Sans font */
}